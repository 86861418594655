<template>
  <div class="sub-header-thin-div">
    <v-card :class="`common-content-div ${isMobile ? '' : 'w-75 mx-auto my-25'}`">
      <v-card-text class="center">
        <p :class="`font-bold color-white ${isMobile ? 'font-30 mt-10' : 'font-40 mt-20'}`">{{ $t('help_center').toUpperCase() }}</p>
      </v-card-text>
      <div class="m-5 row d-flex justify-content-between">
        <div class="m-5 vertical-center">
          <img :src="assets.partner" :width="60" :height="60" />
          <div :class="isMobile ? 'm-2' : 'm-5'">
            <p :class="`font-bold color-white ${isMobile ? 'font-15' : 'font-20'}`">{{ $t('contact_partner') }}</p>
            <a href="mailto:info@eventboxde.com">
              <span :class="`color-white ${isMobile ? 'font-12' : 'font-15'}`">info@eventboxde.com</span>
            </a>
          </div>
        </div>
        <div class="m-5 vertical-center">
          <img :src="assets.inquiries" :width="60" :height="60" />
          <div :class="isMobile ? 'm-2' : 'm-5'">
            <p :class="`font-bold color-white ${isMobile ? 'font-15' : 'font-20'}`">{{ $t('contact_inquiries') }}</p>
            <a href="mailto:info@eventboxde.com">
              <span :class="`color-white ${isMobile ? 'font-12' : 'font-15'}`">info@eventboxde.com</span>
            </a>
          </div>
        </div>
        <div class="m-5 vertical-center">
          <img :src="assets.privacy" :width="60" :height="60" />
          <div :class="isMobile ? 'm-2' : 'm-5'">
            <p :class="`font-bold color-white ${isMobile ? 'font-15' : 'font-20'}`">{{ $t('contact_privacy') }}</p>
            <a href="mailto:info@eventboxde.com">
              <span :class="`color-white ${isMobile ? 'font-12' : 'font-15'}`">info@eventboxde.com</span>
            </a>
          </div>
        </div>
      </div>
      <p class="my-20 d-flex justify-content-around">
      </p>
    </v-card>
  </div>
</template>

<script>
import partner from '@/assets/images/partner.png';
import inquiries from '@/assets/images/inquiries.png';
import privacy from '@/assets/images/privacy.png';

export default {
  name: 'Contact',
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  },
  data() {
    return {
      assets: {
        partner,
        inquiries,
        privacy
      }
    };
  },
};
</script>
